@import "variables";

@import "~bootstrap/scss/bootstrap.scss";

$green: #005A28;
$red: #EC000C;
$blue: #0A3CA0;
$bluesec: #00538A;

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
}

body {
  position: relative;
  overflow-x: hidden;
}

a, svg, svg circle, svg path, img, span, button {
    transition: all 200ms ease-out;
}
::placeholder {
    color: #c9c9c9;
}
::-ms-input-placeholder {
    color: #c9c9c9;
}

.buttonRed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 175px;
    height: 47px;
    border: 1px solid $red;
    border-radius: 33px;
    background: $red;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    transition: all 200ms ease-out;
    span {
      margin-right: -35px;
    }
    svg {
      margin-left: 15px;
      opacity: 0;
    }
    &:hover {
      span {
        margin-right: 0px;
      }
      svg {
        opacity: 1;
      }
    }
}

h1 {
    color: $green;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}

.slogan {
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 100%;
}

@media (max-width: 575px) {
  .slogan {
    img {
      max-width: 365px;
    }
  }
}

.floating-hand {
  position: absolute;
  top: 65px;
  right: -215px;
  width: 50%;
  z-index: 99;
  img {
    width: 100%;
    min-width: 850px;
    max-width: 1100px;
  }
}

.floating-hand-no-animatiom {
  position: absolute;
  top: 100px;
  right: 0px;
  width: 50%;
  z-index: 1;
  &.blue-variant {
    top: -40px;
    right: 0px;
  }
  img {
    width: 100%;
    min-width: 850px;
    max-width: 1100px;
  }
}

.floating-hand-no-animatiom-second {
  position: absolute;
  top: 130px;
  right: -215px;
  width: 50%;
  z-index: 1;
  &.blue-variant {
    top: -40px;
    right: 0px;
  }
  img {
    width: 100%;
    min-width: 850px;
    max-width: 1100px;
  }
}

.floating-hand-no-animatiom-second {
  display: none;
}

@media (max-width: 1399px) {
  .floating-hand-no-animatiom {
    right: -115px;
  }
}

@media (max-width: 1199px) {
  .floating-hand {
    top: 125px;
    right: -185px;
  }
  .floating-hand-no-animatiom {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .floating-hand {
    right: 0px;
  }
}

@media (max-width: 767px) {
  .floating-hand {
    top: 185px;
    img {
      min-width: 700px;
    }
  }
  .floating-hand-no-animatiom, .floating-hand-no-animatiom-second {
    img {
      min-width: 700px;
    }
    &.blue-variant {
      top: 35px;
      right: -20px;
    }
  }
}

/*------------modal---------------*/

#contactUs, #emailModalSuccess{
  .modal-content {
    width: 465px;
    max-width: 100%;
    height: 600px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .modal-header {
      border: none;
      padding-bottom: 10px;
      img {
        width: 145px;
      }
      .emailSuccess {
        color: $green !important;
        text-align: center !important;
        font-family: Inter !important;
        font-size: 40px !important;
        font-style: normal !important;
        font-weight: 900 !important;
        line-height: 52px !important; /* 130% */
        text-transform: uppercase !important;
      }
      div {
        &:first-of-type {
          color: $green;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 112.5% */
          margin-top: -10px;
          padding-bottom: 2px;
        }
        &:last-of-type {
          color: #1E1E1E;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
    .modal-footer {
      border: none;
      margin-bottom: 25px;
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      input {
        width: 330px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        background: #FFF;
        margin-bottom: 15px;
      }
      textarea {
        width: 330px;
        height: 155px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        background: #FFF;
      }
      .infoForm {
        max-width: 250px;
        margin: 0 auto;
        text-align: center;
        a {
          color: #C4C4C4;
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          text-decoration: none;
          &:hover {
            color: $red;
          }
        }
      }
      button {
        width: 142px;
        height: 50px;
        flex-shrink: 0;
        color: #FFF;
        border: 1px solid $red;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:hover {
          color: $red;
          background: #fff;
        }
      }
    }
  }
}

/*-------------navigation---------*/

.hamburger-menu {
  position: relative;
  width: 40px;
  height: 40px;
}

#menu__toggle {
  position: absolute;
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
  background-color: #fff;
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background-color: #fff;
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  position: relative;
  right: 0px;
  width: 35px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: $green;
  transition-duration: .25s;
}

.blue-variant .menu__btn > span,
.blue-variant .menu__btn > span::before,
.blue-variant .menu__btn > span::after {
  background-color: $blue;
}
.blue-blue-variant .menu__btn > span,
.blue-blue-variant .menu__btn > span::before,
.blue-blue-variant .menu__btn > span::after {
  background-color: #fff;
}
.white .menu__btn > span,
.white .menu__btn > span::before,
.white .menu__btn > span::after {
  background-color: #fff;
}

.menu__btn > span::before {
  content: '';
  top: -12px;
  width: 40px;
}
.menu__btn > span::after {
  content: '';
  top: 12px;
  width: 40px;
}
.menu__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: $green;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: .25s;
}
.blue-variant .menu__box {
  background-color: $blue;
}

.menu__item {
  font-family: Inter;
  font-size: 30px;
  letter-spacing: -0.02em;
  line-height: 75px;
  color: #fff !important;
  text-decoration: none;
  transition-duration: .25s;
  padding: 0px 30px;
  &:hover {
    color: $red !important;
  }
}


@media (max-width: 1899px) {
  .menu__box {
      width: 100%px;
  }
}

/*------------end Navigation-----------*/


/*---------------special button---------*/

.toggle-button-cover {
  position: relative;
  height: 50px;
  width: 100%;
  margin-bottom: 70px;
  .button-cover:before
  {
      counter-increment: button-counter;
      right: 0;
      bottom: 0;
      color: #d7e3e3;
      font-size: 12px;
      line-height: 1;
      padding: 5px;
  }

  .button-cover, .knobs, .layer
  {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
  }

  .button
  {
      position: relative;
      top: 50%;
      width: 190px;
      height: 50px;
      margin: -20px auto 0 auto;
      overflow: hidden;
  }

  .button.r, .button.r .layer
  {
      border-radius: 100px;
  }

  .button.b2
  {
      border-radius: 2px;
  }

  .checkbox
  {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
  }

  .knobs
  {
      z-index: 2;
  }

  .layer
  {
      width: 100%;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 25px;
      transition: 0.2s ease all;
      z-index: 1;
  }
  /* Button 10 */
  #button-10 .knobs:before, #button-10 .knobs:after, #button-10 .knobs span
  {
      position: absolute;
      top: 0px;
      width: 100px;
      height: 50px;
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 15px !important;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 15px 4px;
      border-radius: 2px;
      transition: 0.3s ease all;
  }
  
  #button-10 .knobs:before
  {
      content: '';
      left: 0px;
      background-color: $red;
      border-radius: 25px;
  }
  
  #button-10 .knobs:after
  {
      content: 'Mango';
      right: -190px;
      font-size: 15px;
      color: #fff;
  }
  
  #button-10 .knobs span
  {
      display: inline-block;
      left: 0px;
      color: #fff;
      font-size: 12px;
      z-index: 1;
  }
  
  #button-10 .checkbox:checked + .knobs span
  {
      color: #fff;
  }
  
  #button-10 .checkbox:checked + .knobs:before
  {
      left: 90px;
      background-color: $red;
  }
  
  #button-10 .checkbox:checked + .knobs:after
  {
      color: #fff;
  }
  
  #button-10 .checkbox:checked ~ .layer
  {
      background-color: transparent;
  }
}

/*--------------header---------------*/

header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    background: transparent;
    z-index: 100;
    .logo {
      margin-top: 25px;
        img {
            width: 175px;
        }
    }
    ul {
        display: flex;
        align-items: center;
        margin: 0;
        li {
            list-style: none;
            padding: 0 20px;
            &:last-of-type {
              a {
                color: $red !important;
              }
            }
            a {
                color: $green;
                text-align: center;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-decoration: none;
                &:hover {
                    color: $red;
                }
            }
        }
    }
    .social-media {
        a {
            &:last-of-type {
                margin-left: 20px;
            }
            svg {
                &:hover {
                    path {
                        fill: $red
                    }
                }
            }
        }
    }

    .white {
      ul {
        li {
          a {
            color: #fff;
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
    .blue-variant {
      ul {
        li {
          a {
            color: $blue;
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
    .blue-blue-variant {
      ul {
        li {
          a {
            color: #fff;
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
}

#ageCheck {
  .modal-content {
    position: relative;
    width: 100%;
    height: 100dvh;
    background: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/zlaty-bazant-bg.png) center center no-repeat;
        opacity: 0.20;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/srafovani_bila_1.png) top center repeat;
        opacity: 0.20;
    }
    .ageText {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 45px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }
    .ageTextSecond {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 10px 0 50px;
    }
    .ageButton {
      display: flex;
      justify-content: center;
      gap: 15px;
      button {
        &:first-of-type {
          display: flex;
          width: 135px;
          height: 50px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border: 1px solid #fff;
          border-radius: 33px;
          background: #fff;
          color: $red;
          text-align: center;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &:hover {
            border: 1px solid $red;
            background: $red;
            color: #fff;
          }
        }
        &:last-of-type {
          display: flex;
          width: 97px;
          height: 50px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border: 1px solid #fff;
          border-radius: 33px;
          background: transparent;
          color: #FFF;
          text-align: center;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &:hover {
            background: #fff;
            color: $red;
          }
        }
      }
    }
  }
}

#ageCheckFalse {
  .modal-content {
    position: relative;
    width: 100%;
    height: 100dvh;
    background: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/zlaty-bazant-bg.png) center center no-repeat;
        opacity: 0.20;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/srafovani_bila_1.png) top center repeat;
        opacity: 0.20;
    }
    .ageText {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 45px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      max-width: 800px;
      margin: 0 auto;
    }
  }
}

/*-------------homepage--------------*/

.home {
    position: relative;
    width: 100%;
    height: 850px;
    padding-top: 125px;
    background: linear-gradient(270deg, #96773F 0%, #B08D4C 50%, #B99B5E 100%);
    &::before {
        content: '';
        position: absolute;
        left: calc(50% - 75px);
        top: 145px;
        width: 580px;
        height: 580px;
        border-radius: 50%;
        background-color: $green;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/srafovani_bila_1.png) top center repeat;
        //background: repeating-linear-gradient(-25deg, #B99B5E, #B99B5E 4px, #96773F 4px, #96773F 8px);
        opacity: 0.20;
    }
    h1 {
      margin-bottom: 25px;
    }
    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .header-text {
        padding-left: 125px;
    }
    .green-circle {
        position: relative;
        padding-left: 25px;
        margin-top: -165px;
        img {
            width: 205px;
            height: auto;
        }
    }
}

@media (max-width: 1199px) {
  .home {
    .header-text {
      padding-left: 12px;
    }
  }
}

@media (max-width: 991px) {
  .home {
    &::before {
      display: none;
    }
    .green-circle {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .home {
    height: 720px;
  }
}

.home-about {
    position: relative;
    width: 100%;
    height: 720px;
    background: $green;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/zlaty-bazant-bg.png) center center no-repeat;
        opacity: 0.20;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/srafovani_bila_1.png) top center repeat;
        opacity: 0.20;
    }
    h2 {
        color: #FFF;
        font-family: Inter;
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
    }
}

@media (max-width: 575px) {
  .home-about {
    h2 {
      font-size: 38px;
    }
  }
}

.home-product {
    position: relative;
    width: 100%;
    height: 720px;
    background: linear-gradient(270deg, #96773F 0%, #B08D4C 50%, #B99B5E 100%);
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/srafovani_bila_1.png) top center repeat;
        //background: repeating-linear-gradient(-25deg, #B99B5E, #B99B5E 4px, #96773F 4px, #96773F 8px);
        opacity: 0.20;
    }
    .plech-shadow {
        position: absolute;
        right: 8px;
        bottom: -85px;
        opacity: 1;
        z-index: -1;
    }
    .plech-shine {
        position: absolute;
        top: -45%;
        left: -65%;
        opacity: 0;
        z-index: -1;
    }
    a {
        position: relative;
        .plech {
            max-width: 175px;
        }
        &:hover {  
            .plech {
              scale: 1.1;
            }
            & ~ .plech-shadow {
                opacity: 0;
            }
            & ~ .plech-shine {
                opacity: 1;
            }
        }
    }
}

@media (max-width: 1399px) {
  .home-product {
    .plech-shadow {
      right: -10px;
    }
  }
}
@media (max-width: 1199px) {
  .home-product {
    .plech-shadow {
      right: -35px;
    }
  }
}
@media (max-width: 991px) {
  .home-product {
    height: 1175px;
    .row {
      row-gap: 75px;
    }

    .plech-shadow {
      right: 0;
      left: 55px;
      margin: 0 auto;
      bottom: -85px;
    }
  }
}
@media (max-width: 767px) {
  .home-product {
    height: auto;
    padding: 150px 0;
    .row {
      row-gap: 75px;
    }
    .plech-shine {
      top: -45%;
      left: -100px;
      right: 0;
      margin: 0 auto;
    }
  }
}

.containerr {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: 38px;
  color: #616161;
  font-style: italic;
  font-weight: 800;
}

.timeline {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.2);
}
.timeline .swiper-container {
  height: 720px;
  width: 100%;
  position: relative;
}
.timeline .swiper-wrapper {
  transition: 1s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.timeline .swiper-slide {
  position: relative;
  color: #fff;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.timeline .swiper-slide::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: -115%;
  bottom: -10%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: -230px 0 150px 60vw rgba(0, 0, 0, 0.1);
  border-radius: 100%;
}
.timeline .swiper-slide-content {
  position: absolute;
  text-align: center;
  width: 80%;
  left: 0;
  right: 0;
  top: 13%;
  transform: translate(50%, 0);
  font-size: 12px;
  z-index: 2;
}
.timeline .swiper-slide .timeline-year {
  display: block;
  font-style: italic;
  font-size: 42px;
  margin-bottom: 50px;
  transform: translate3d(20px, 0, 0);
  color: #d4a024;
  font-weight: 300;
  opacity: 0;
  transition: 0.2s ease 0.4s;
}
.timeline .swiper-slide .timeline-title {
  color: #FFF;
  font-family: Inter;
  font-size: 75px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: 0.2s ease 0.5s;
}
.timeline .swiper-slide .timeline-text {
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  max-width: 450px;
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: 0.2s ease 0.6s;
}
.timeline .swiper-slide-active .timeline-year {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 1.6s;
}
.timeline .swiper-slide-active .timeline-title {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 0.8s;
}
.timeline .swiper-slide-active .timeline-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 1s;
}
.timeline .swiper-pagination {
  right: 4% !important;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  z-index: 1;
}
.timeline .swiper-pagination::before {
  /*content: "";*/
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  width: 1px;
}
.timeline .swiper-pagination-bullet {
  width: auto;
  height: auto;
  text-align: center;
  opacity: 1;
  background: transparent;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 15px 0 !important;
  position: relative;
}
.timeline .swiper-pagination-bullet::before {
  /*content: "";*/
  position: absolute;
  top: 8px;
  left: -32.5px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #d4a024;
  transform: scale(0);
  transition: 0.2s;
}
.timeline .swiper-pagination-bullet-active {
  color: $red;
}
.timeline .swiper-pagination-bullet-active::before {
  transform: scale(1);
}
.timeline .swiper-button-next,
.timeline .swiper-button-prev {
  background-size: 50px 50px;
  top: calc(50% - 10px);
  width: 50px;
  height: 50px;
  margin-top: 0;
  z-index: 2;
  transition: 0.2s;
}
.timeline .swiper-button-next {
  right: 65px;
  background-image: url(../images/arrow-right.svg);
}
.timeline .swiper-button-next:hover {
  transform: translateX(3px);
}
@media screen and (min-width: 768px) {
  .timeline .swiper-slide::after {
    right: -30%;
    bottom: -8%;
    width: 240px;
    height: 50%;
  }
  .timeline .swiper-slide-content {
    right: 0%;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 11px;
    text-align: left;
  }
  .timeline .swiper-slide .timeline-year {
    margin-bottom: 0;
    font-size: 32px;
  }
  .timeline .swiper-slide .timeline-title {
    font-size: 75px;
    margin: 0 0 20px;
  }
  .timeline .swiper-pagination {
    display: flex;
    align-items: flex-end;
  }
  .timeline .swiper-button-next {
    right: 65px;
  }
  .timeline .swiper-button-next:hover {
    transform: translate(10px, 0px);
  }
}
@media screen and (min-width: 1024px) {
  .timeline .swiper-slide::after {
    right: -20%;
    bottom: -12%;
    width: 240px;
    height: 50%;
  }
  .timeline .swiper-slide-content {
    
  }
}

@media (max-width: 767px) {
  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    left: -50px !important;
  }
  .timeline .swiper-button-next,
  .timeline .swiper-button-prev {
    position: absolute;
    top: calc(90% - 10px);
    left: calc(50% - 25px);
    right: 0;
    bottom: 0;
  }
  .timeline .swiper-slide-content {
    width: 70%;
    left: 0;
    right: 0;
    top: 40%;
    transform: translateY(-5%);
  }
  .timeline .swiper-slide .timeline-title {
    font-size: 50px;
  }
}


.home-video {
    width: 100%;
    margin-bottom: -8px;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .iframe-placeholder {
      width: 100%;
      height: 100%;
      background: url(../images/video.jpg) center center / cover;
      cursor: pointer;
    }
}

@media (max-width: 991px) {
  .home-video {
    height: 550px;
  }
}

footer {
  position: relative;
  width: 100%;
  height: 720px;
  background: $green;
  &.blue-variant {
    background: $blue;
  }
  &.blue-sec-variant {
    background: $bluesec;
  }
  &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(../images/srafovani_bila_1.png) top center repeat;
      opacity: 0.20;
  }
  .container {
    &:first-of-type {
      height: 650px;
    }
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  button {
    color: #FFF;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    background: transparent;
    border: none;
    &:hover {
      color: $red;
    }
  }

  .copyright {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .links {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    a {
      color: #FFF;
      text-align: right;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration: none;
      &:hover {
        color: $red;
      }
    }
  }
}

@media (max-width: 1199px) {
  footer {
    .container {
      &:first-of-type {
        height: 545px;
      }
    }
    .copyright {
      padding: 15px 0;
    }
    .links {
      align-items: center;
      margin-top: 15px;
    }
  }
}

/*---------------product detail---------*/

.product-detail {
  position: relative;
  width: 100%;
  height: 720px;
  background: $green;
  &.blue-variant,  &.blue-sec-variant {
    background: #f7f7f7;
  }
  &.blue-blue-variant {
    background: $blue;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/zlaty-bazant-bg.png) center center no-repeat;
    opacity: 0.20;
  }
  &.blue-variant::before, &.blue-sec-variant::before {
    background: url(../images/zlaty-bazant-bg-bila.png) center center no-repeat;
    opacity: 0.85;
  }
  &.blue-blue-variant::before {
    background: url(../images/zlaty-bazant-bg-bila.png) center center no-repeat;
    opacity: 0.08;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/srafovani_bila_1.png) top center repeat;
    opacity: 0.20;
  }
  h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 75px;
    &.blue-variant {
      color: $blue;
      span {
        color: $red
      }
    }
    &.blue-sec-variant {
      color: $bluesec;
      span {
        color: $red
      }
    }
    &.blue-blue-variant {
      color: #fff;
    }
  }
  p {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0 40px;
    &.blue-variant {
      color: $blue;
    }
    &.blue-sec-variant {
      color: $bluesec;
    }
    &.blue-blue-variant {
      color: #fff;
    }
  }
  .header-marks {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header-text {
      padding-left: 250px;
  }
  .alkohol {
    width: 80px;
  }
}

@media (max-width: 1199px) {
.product-detail {
  .header-text {
    padding-left: 12px;
  }
}
}

@media (max-width: 991px) {
.product-detail {
  &::before {
    display: none;
  }
  .green-circle {
    display: none;
  }
}
}

.product-detail-text {
  position: relative;
  width: 100%;
  height: 550px;
  background: #fff;
  h2 {
      color: #FFF;
      font-family: Inter;
      font-size: 50px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
  }
  p {
    color: $green;
    text-align: justify;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    max-width: 700px;
    margin-bottom: 45px;
    &.blue-variant {
      color: $blue;
    }
    &.blue-sec-variant {
      color: $bluesec;
    }
    strong {
      font-weight: 700;
    }
  }
  .receptura {
    max-width: 480px;
    margin-bottom: 30px;
  }

  .variant-header {
    color: $green;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding-bottom: 40px;
    &.blue-variant {
      color: $blue;
    }
    &.blue-sec-variant {
      color: $bluesec;
    }
  }
  .variant-boxes {
    gap: 35px;
    .variant-box {
      .variant-name {
        color: $green;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .variant-variant {
        width: 75px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
      &.blue-variant {
        .variant-name {
          color: $blue;
        }
        .variant-variant {
          color: $blue;
        }
      }
      &.blue-sec-variant {
        .variant-name {
          color: $bluesec;
        }
        .variant-variant {
          color: $bluesec;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .product-detail-text {
    height: 700px;
    h2 {
      font-size: 38px;
    }
    p {
      padding: 0 25px;
    }
    .receptura {
      max-width: 340px;
    }
  }
}

.product-detail-info {
  position: relative;
  width: 100%;
  height: 510px;
  background: #fff;
  .material-1, .material-2 {
    display: flex;
  }
  .material-2 {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background: $green;
  }
  &.blue-variant::before {
    background: $blue;
  }
  &.blue-sec-variant::before {
    background: $bluesec;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background: url(../images/srafovani_bila_1.png) top center repeat;
    opacity: 0.20;
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 35px;
  }
  p {
    color: #FFF;
    text-align: justify;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 164.706% */
    padding: 0 20px 0 20px;
    width: calc(100% / 1.5);
    max-width: 699px;
    strong {
      font-weight: 700;
    }
  }

  table {
    &.table-2 {
      display: none;
    }
    tr {
      &:nth-of-type(odd) {
        background: #F7f7f7;
      }
      &:nth-of-type(even) {
        background: #fff;
      }
      &:first-of-type {
        td {
          color: $green;
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          height: 85px;
          &.blue-variant {
            color: $blue;
          }
          &.blue-sec-variant {
            color: $blue;
          }
        }
      }
      td {
        vertical-align: middle;
        &:first-of-type {
          color: #1E1E1E;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px; /* 147.059% */
          padding-left: 100px;
        }
        &:last-of-type {
          color: $green;
          text-align: right;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px; /* 147.059% */
          padding-right: 100px;
          &.blue-variant {
            color: $blue;
          }
          &.blue-sec-variant {
            color: $bluesec;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .product-detail-info {
    height: auto;
    &::before {
      width: 100%;
    }
    &::after {
      width: 100%;
    }
    p {
      width: 100%;
    }
    .row {
      & > div {
        &:first-of-type {
          padding-top: 75px;
          padding-bottom: 75px;
        }
      }
    }
    table {
      tr {
        td {
          height: 65px;
          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            padding-right: 20px;
          }
        }
      }
    }
  }
}

.private-data, .cookies-data {
  position: relative;
  width: 100%;
  height: 375px;
  background: $green;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/zlaty-bazant-bg-bila.png) center center no-repeat;
    opacity: 0.1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/srafovani_bila_1.png) top center repeat;
    opacity: 0.20;
  }
  h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 55px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 75px;
  }
  h2 {
    color: $green;
    text-align: justify;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px; /* 138.889% */
  }

  p {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0 40px;
    &.blue-variant {
      color: $blue;
    }
    &.blue-blue-variant {
      color: #fff;
    }
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header-text {
      padding-left: 125px;
  }
  .alkohol {
    width: 80px;
  }
}

.private-data-text, .cookies-data-text {
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0 auto;
  max-width: 820px;
  h2 {
    color: $green;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px; /* 138.889% */
    margin-top: 60px;
    padding-left: 0;
  }
  .row {
    &:first-of-type {
      h2 {
        margin-top: 0px;
      }
    }
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 40px 0 0px;
    &.blue-variant {
      color: $blue;
    }
    &.blue-blue-variant {
      color: #fff;
    }
  }
  ul {
    margin-top: 40px;
    li {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 156.25% */
      list-style: disc;
      margin-left: 15px;
    }
  }
  a {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header-text {
      padding-left: 125px;
  }
  .alkohol {
    width: 80px;
  }
}